import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import PageScrollTop from './component/PageScrollTop';
import Paralax from './home/Paralax';
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import error404 from "./elements/error404";
import Terms from "./elements/Terms";
import Privacy from "./elements/Privacy";
import StandardiPdf from './elements/Standardi';
import Portfolio from "./blocks/Portfolio";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Fakultis from "./elements/aplikacije/Fakultis";
import Nasport from "./elements/aplikacije/Nasport";
import Upitnik from "./elements/aplikacije/Upitnik";
import Eindeks from "./elements/aplikacije/Eindeks";
import Diploma from "./elements/aplikacije/Diploma";
import Cronus from "./elements/aplikacije/Cronus.jsx";
import Cronusbank from "./elements/aplikacije/Cronusbank.jsx";
import Raspodela from "./elements/aplikacije/Raspodela.jsx";
import Upis from "./elements/aplikacije/Upis.jsx";
import CronusGo from "./elements/aplikacije/CronusGo.jsx";
import Mindeks from "./elements/aplikacije/Mindeks.jsx";
import Eraspodela from "./elements/aplikacije/Eraspodela.jsx"
import FakultisKomunikator from "./elements/aplikacije/FakultisKomunikator";
import Enadzor from './elements/aplikacije/Enadzor';
import Tecomp from './elements/aplikacije/Tecomp';
import Eobjedini from './elements/aplikacije/Eobjedini';
import Sopri from './elements/aplikacije/Sopri';
import Anomalija from './elements/aplikacije/Anomalija';
import Feba from './elements/aplikacije/Feba';
import Vaga from './elements/aplikacije/Vaga';

window.location.href = "/v2";

class Root extends Component{
    render(){
        return null
        // (
        //     <BrowserRouter basename={'/'}>
        //         <PageScrollTop>
        //             <Switch>
        //             <Route path="*" render={() => <Redirect to="/v2/" />} />
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/`} component={Paralax}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/snaga`} component={ServiceDetails}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/fakultis`} component={Fakultis}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/nasport`} component={Nasport}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/upitnik`} component={Upitnik}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/eindeks`} component={Eindeks}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/diploma`} component={Diploma}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/cronus`} component={Cronus}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/cronusbank`} component={Cronusbank}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/cronusgo`} component={CronusGo}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/mindeks`} component={Mindeks}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/komunikator`} component={FakultisKomunikator} />*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/eraspodela`} component={Eraspodela} />*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/enadzor`} component={Enadzor} />*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/tecomp`} component={Tecomp} />*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/eobjedini`} component={Eobjedini} />*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/anomalija`} component={Anomalija} />*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/raspodela`} component={Raspodela}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/upis`} component={Upis}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/sopri`} component={Sopri}/>*/}
		// 			{/*<Route exact path={`${process.env.PUBLIC_URL}/terms`} component={Terms}/>*/}
		// 			{/*<Route exact path={`${process.env.PUBLIC_URL}/privacy`} component={Privacy}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/standardi`} component={StandardiPdf}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/feba`} component={Feba}/>*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/vaga`} component={Vaga}/>*/}
        //             {/*<Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>*/}
        //             {/*<Route component={error404}/>*/}
        //             </Switch>
        //         </PageScrollTop>
        //     </BrowserRouter>
        // )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.unregister();